const _temp0 = require("./images/vizualizers/viz_1.webp");
const _temp1 = require("./images/vizualizers/viz_10.webp");
const _temp2 = require("./images/vizualizers/viz_11.webp");
const _temp3 = require("./images/vizualizers/viz_12.webp");
const _temp4 = require("./images/vizualizers/viz_13.webp");
const _temp5 = require("./images/vizualizers/viz_14.webp");
const _temp6 = require("./images/vizualizers/viz_2.webp");
const _temp7 = require("./images/vizualizers/viz_3.webp");
const _temp8 = require("./images/vizualizers/viz_4.webp");
const _temp9 = require("./images/vizualizers/viz_5.webp");
const _temp10 = require("./images/vizualizers/viz_6.webp");
const _temp11 = require("./images/vizualizers/viz_7.webp");
const _temp12 = require("./images/vizualizers/viz_8.webp");
const _temp13 = require("./images/vizualizers/viz_9.webp");
module.exports = {
  "viz_1": _temp0,
  "viz_10": _temp1,
  "viz_11": _temp2,
  "viz_12": _temp3,
  "viz_13": _temp4,
  "viz_14": _temp5,
  "viz_2": _temp6,
  "viz_3": _temp7,
  "viz_4": _temp8,
  "viz_5": _temp9,
  "viz_6": _temp10,
  "viz_7": _temp11,
  "viz_8": _temp12,
  "viz_9": _temp13
}