import { gsap } from 'gsap'
import barba from '@barba/core'
import LocomotiveScroll from 'locomotive-scroll';
// Vendors
import 'locomotive-scroll/dist/locomotive-scroll.css';
// All images from vizualizer folder...
import * as visualizers from './images/vizualizers/*.webp'
import play_button from './images/play-button.png'
//Media
import sound_on from './images/sound_on.png'
import sound_off from './images/sound_toggle.png'
import play_button from './images/play.png'
import pause from './images/pause.png'

var scroll

const initScroll = ()=>{
     scroll = new LocomotiveScroll({
        lenisOptions: {
            wrapper: window,
            content: document.querySelector('[data-scroll-container]'),
        }
    });
    
}

const destroyScroll = ()=>{
    if(scroll){
        scroll.destroy()
        scroll=null
    }
}


 

const page_transition = document.querySelectorAll('.page_transition div')
const tl = gsap.timeline()
barba.init({

    sync: true,

    transitions: [
        {
            name: 'page-transition',

            

            leave(data) {
                const done = this.async();
                gsap.to(data.current.container, {
                    y: -200,
                    opacity: 0,
                    duration: 1,
                    onComplete: function () {
                        done();
                        
                    },
                });

                tl.to(page_transition, {
                    duration: .5,
                    display: 'flex',
                    y: 0,
                    stagger: .1,

                })

                tl.to(page_transition, {
                    duration: .5,
                    delay: .3,
                    y: '-100%',
                    stagger: .15
                })



            },
            beforeEnter(){
                destroyScroll()
            },
            enter(data) {
                initScroll()
                gsap.from(data.next.container, {
                    y: -100,
                    opacity: 0,
                    delay: .3,
                    duration: 1,
                    ease: 'power2.out',
                    
                });

                scroll.scrollTo(0, 0);
                
                

            },
            once(data) {
                initScroll()
                gsap.from(data.next.container, {
                    y: -100,
                    opacity: 1,
                    duration: 1,
                    delay: .3,
                    ease: 'power2.out'
                });

               


            }
        }
    ],
    views: [
        {
            namespace: 'home',
            afterEnter() {
                    const parentFunction = ()=>{


                    /* Custom event */
                    window.addEventListener("scrollEvent", (e) => {
                        console.log('this')
                        const { target, way } = e.detail;
                    
                        if (way === "enter") {
                            target.style.opacity = 1;
                            target.style.paddingTop = 0
                        } else {
                            target.style.opacity = 0;
                        }
                    });
                    
                    const works_heading = document.querySelector('#works_heading')
                    const challenge_img = document.querySelector('.challenge_img')
                    const challenge_content = document.querySelector('#challenge_content')
                    const contact_img = document.querySelector('.contact_img')
                    const contact_content = document.querySelector('#contact_content')
                    const footer_arr = [challenge_img, challenge_content, contact_img, contact_content]
                    const arrow_up = document.querySelector('#arrow_up')
                    const arrow_down = document.querySelector('#arrow_down')
                    const video_trey = document.querySelector('.video_trey')
                    
                    const works_array = 'OUR WORKS'.split('')
                    
                    
                    works_array.forEach(items => {
                        const span = document.createElement('span')
                        span.textContent = items
                    
                        works_heading.appendChild(span)
                    
                    
                    
                    })
                    
                    const spans = works_heading.querySelectorAll('span')
                    
                    window.addEventListener('animTrigger', () => {
                        gsap.to(spans, {
                            alpha: 1,
                            top: 0,
                            stagger: .1,
                            duration: 1.5,
                            ease: 'power4.out'
                        })
                    })
                    
                    window.addEventListener('contactTrigger', () => {
                        gsap.to(footer_arr, {
                            alpha: 1,
                            stagger: .2,
                            duration: 1.5,
                            ease: 'power4.out'
                        })
                    })
                    
                    window.addEventListener('latestTrigger', () => {
                        //latest Project Loader
                        const svg = document.querySelector('svg circle')
                        const content_trey = document.querySelector('.content_trey')
                        const flex2_trey = document.querySelector('.flex2_trey')
                        const last_night_caption_trey = document.querySelector('.last_night_caption_trey')
                        const poster2_trey = document.querySelector('.poster2_trey')
                        const latest_array = [content_trey, flex2_trey, last_night_caption_trey, poster2_trey]
                    
                        const timer = 12000
                        var index = 1;
                        var total_index = 2;
                        const duration_time = 11
                        let loadInterval
                    
                        const svg_tl = gsap.timeline({ paused: false })
                    
                    
                    
                        svg_tl.to(svg, {
                            strokeDashoffset: 0,
                            duration: duration_time
                        })
                    
                    
                        function timeInterval() {
                            loadInterval = setInterval(() => {
                                if (index < total_index) {
                                    svg_tl.to(svg, {
                                        strokeDashoffset: 251,
                                        duration: duration_time
                                    })
                                    svg.style.stroke = 'red'
                                    gsap.to(latest_array, {
                                        transform: 'translate(-50%)',
                                        stagger: .2,
                                        ease: 'power4.out',
                                        duration: 1
                                    })
                                    index++
                                } else {
                                    index = 1
                                    svg_tl.to(svg, {
                                        strokeDashoffset: 0,
                                        duration: duration_time
                                    })
                                    gsap.to(latest_array, {
                                        transform: 'translate(0%)',
                                        stagger: .2,
                                        ease: 'power4.out',
                                        duration: 1
                                    })
                                    svg.style.stroke = 'blue'
                                    // loading_bar.style.transition = 'none'
                                    // loading_bar.style.width = '0'
                                    //loading_bar.style.display='none'
                                }
                    
                            }, timer)
                        }
                    
                    
                        timeInterval()
                    
                        const pause_button = document.querySelector('.pause_button')
                        var pause_bool = true;
                    
                        pause_bool = !pause_bool
                        pause_button.onclick = (e) => {
                            svg_tl.paused(!svg_tl.paused());
                            if (pause_bool = !pause_bool) {
                                e.target.setAttribute('src', play_button)
                                clearInterval(loadInterval)
                    
                            } else {
                                e.target.setAttribute('src', pause)
                                timeInterval()
                            }
                        }
                    })
                    
                    
                    
                    var move_amount = 80
                    var value = 0
                    var index = 1
                    var total_index = video_trey.children.length
                    
                    
                    function reelDown(){
                        if (index < total_index) {
                    
                            video_trey.style.transform = `translateY(${value -= move_amount}vh)`
                            index++
                        }
                    }
                    
                    arrow_down.onclick = () => {
                        
                        reelDown()
                    
                    }
                    
                    function reelUp(){
                        if (index > 1) {
                            index--
                            video_trey.style.transform = `translateY(${value += move_amount}vh)`
                            
                        }
                    }
                    
                    arrow_up.onclick = () => {
                    
                        reelUp()
                    
                    
                    }
                    
                    //Scroll Trigger
                    window.addEventListener('reelTrigger', () => {
                        const reel_heading = document.querySelector('.reel_heading')
                        const reel_detail = document.querySelector('#details')
                        const reels = document.querySelector('#reels')
                        const reel_buttons = document.querySelector('#reel_buttons')
                        const reel_array = [reel_heading, reel_detail, reels, reel_buttons]
                    
                        gsap.to(reel_array, {
                            alpha: 1,
                            stagger: .2
                        })
                    
                    })
                    
                    
                    // Toggle light or dark mode
                    const toggle_mode = document.querySelector('.toggle_mode')
                    var toggle = true
                    var key = 'toggle_key'
                    toggle = !toggle
                    const local_toggle = localStorage.getItem(key)
                    
                    
                    
                    toggle_mode.onclick = () => {
                    
                    
                        if (toggle = !toggle) {
                            localStorage.setItem(key, toggle)
                            document.body.classList.add('darkmode')
                    
                    
                        } else {
                            localStorage.setItem(key, toggle)
                            document.body.classList.remove('darkmode')
                    
                        }
                    }
                    
                    if (local_toggle === 'true') {
                        document.body.classList.add('darkmode')
                    
                    }
                    
                    if (local_toggle === 'false') {
                        document.body.classList.remove('darkmode')
                    
                    }
                    
                    //Responsive Navbar
                    const nav = document.querySelector('nav')
                    var lastScrollPosition = 0
                    
                    window.addEventListener('scroll', () => {
                        // Get the current scroll position
                        var currentScrollPosition = window.scrollY;
                        //console.log(currentScrollPosition)
                    
                        if (currentScrollPosition - lastScrollPosition > 0) {
                            nav.classList.add('add_nav')
                        } else {
                            nav.classList.remove('add_nav')
                            nav.style.height = 'auto'
                        }
                    
                        lastScrollPosition = currentScrollPosition
                    })
                    
                    const reel_button = document.querySelector('#reel_button')
                    const banner_trey = document.querySelector('.banner_trey')
                    const banner_mute = document.querySelector('.banner_mute')
                    const banner_caption = document.querySelector('.banner_caption')
                    const banner_description = document.querySelector('.banner_description')
                    var reel_bool = true
                    
                    reel_bool = !reel_bool;
                    
                    
                    
                    
                    //Loading Animation starts here..
                    const loading = document.querySelector('.loading')
                    const loading_screen = document.querySelector('.loading_screen')
                    const timelne = gsap.timeline()
                    
                    if (loading) {
                        gsap.to(loading, {
                            delay: 1,
                            transform: 'scale(1.2)',
                            duration: 3,
                    
                            onComplete: () => {
                                timelne.to(loading, {
                                    transform: 'scale(1.3)',
                                    duration: .5,
                                })
                    
                                timelne.to(loading, {
                                    delay: .3,
                                    transform: 'scale(1)',
                                    duration: .25,
                                    onComplete: () => {
                                        loading.style.maskImage = 'none'
                                        loading_screen.remove()
                                    }
                    
                                })
                            }
                        })
                    }
                    
                    //Menu pane codes here...
                    const menu = document.querySelector('.menu')
                    var toggle_bool = true
                    
                    toggle_bool = !toggle_bool
                    menu.onclick = () => {
                        //nav.classList.toggle('toggle_nav')
                    
                        if (toggle_bool = !toggle_bool) {
                            gsap.fromTo(nav, {
                                height: 'auto',
                            }, {
                                height: '100dvh',
                                duration: .3,
                                ease: 'power4.in'
                            })
                        } else {
                            gsap.to(nav, {
                                height: 'auto',
                                duration: .03,
                                ease: 'power4.out'
                            })
                        }
                    }
                    
                    //Mobile view codes
                    const main = document.querySelector('.main')
                    const portfolio_container = document.querySelector('.portfolio_container')
                    const card1 = document.querySelector('#card1')
                    const card2 = document.querySelector('#card2')
                    const card3 = document.querySelector('#card3')
                    const card4 = document.querySelector('#card4')
                    const caption = document.querySelector('#caption')
                    const mobile_screen = window.matchMedia('(max-width:500px)')
                    
                    if (mobile_screen.matches) {
                        document.addEventListener('scroll', (e) => {
                    
                            var scrollTop = document.scrollingElement.scrollTop;
                            var main_position = main.offsetTop;
                            const portfolio_position = portfolio_container.offsetTop;
                    
                            card1.setAttribute('data-scroll-speed','0')
                            card2.setAttribute('data-scroll-speed','0')
                            card4.setAttribute('data-scroll-speed','0')
                            // if(scrollTop > main_position+300){
                            //     caption.style.transform = `translateY(-${(scrollTop-(main_position+600))/10}%)`
                            // }
                    
                            // if (scrollTop > portfolio_position - 300) {
                            //     card1.style.transform = `translate(-3%,-${(scrollTop - (portfolio_position - 300)) / 13}%)`
                            // }
                    
                            // if (scrollTop > portfolio_position - 300) {
                            //     card2.style.transform = `translate(0%,-${(scrollTop - (portfolio_position - 300)) / 6}%)`
                            // }
                            // if (scrollTop > portfolio_position - 300) {
                            //     card3.style.transform = `translate(0%,-${(scrollTop - (portfolio_position - 300)) / 9}%)`
                            // }
                    
                            // if (scrollTop > portfolio_position + 200) {
                            //     card4.style.transform = `translate(0%,-${(scrollTop - (portfolio_position + 200)) / 13}%)`
                            // }
                    
                        })
                    }else{
                        card1.setAttribute('data-scroll-speed','.2')
                        card2.setAttribute('data-scroll-speed','.2')
                        card4.setAttribute('data-scroll-speed','.2')
                    }
                    
                    
                    
                    //Reels Section js codes here...
                    const reel_descrip = document.querySelector('.reel_descrip')
                    const summary = String(reel_descrip.innerText).slice(0, 60)
                    
                    console.log(summary)
                    
                    reel_descrip.innerHTML = summary + '...'
                    
                    //Hover for video preview
                    const trigger = document.querySelector('.viz_trigger')
                    const music_trigger = document.querySelector('.music_trigger')
                    const film_trigger = document.querySelector('.film_trigger')
                    const film_cover_vid = document.querySelector('.film_cover video')
                    const cover_vid = document.querySelector('.cover video')
                    const music_cover_vid = document.querySelector('.music_cover video')
                    const music_cover = document.querySelector('.music_cover')
                    const film_cover = document.querySelector('.film_cover')
                    const film_details = document.querySelector('.film_details')
                    const film_p = document.querySelector('.film_p')
                    const card_2 = document.querySelector('.card2')
                    const music_details = document.querySelector('.music_details')
                    const viz_cover = document.querySelector('.viz_cover')
                    const viz_details = document.querySelector('.viz_details')
                    const illustration_cover = document.querySelector('.illustration_cover')
                    const illustration_details = document.querySelector('.illustration_details')
                    
                    trigger.onmouseover = (e) => {
                    
                    
                        cover_vid.style.opacity = 1,
                            cover_vid.style.visibility = 'visible'
                        cover_vid.style.display = 'block'
                        viz_cover.style.transform = 'scale(1.2)'
                        viz_details.style.transform = 'translateY(max(60px,5vh))'
                    
                    
                        console.log(e.target.parentNode)
                    
                    }
                    
                    trigger.onmouseleave = (e) => {
                    
                        cover_vid.style.opacity = 0,
                            cover_vid.style.visibility = 'hidden'
                        cover_vid.style.display = 'none'
                        viz_cover.style.transform = 'scale(1)'
                        viz_details.style.transform = 'translateY(0px)'
                    }
                    
                    music_trigger.onmouseover = (e) => {
                        music_cover_vid.style.opacity = 1
                        music_cover_vid.style.visibility = 'visible'
                        music_cover_vid.style.display = 'block'
                        music_cover.style.transform = 'scale(1.2)'
                    
                        console.log(e.target.parentNode)
                        music_details.style.transform = 'translateY(max(60px,5vh))'
                    }
                    
                    music_trigger.onmouseleave = (e) => {
                        music_cover_vid.style.opacity = 0
                        music_cover_vid.style.visibility = 'hidden'
                        music_cover_vid.style.display = 'none'
                        music_cover.style.transform = 'scale(1)'
                        music_details.style.transform = 'translateY(0px)'
                    }
                    
                    film_trigger.onmouseover = () => {
                        film_cover_vid.style.opacity = 1
                        film_cover_vid.style.visibility = 'visible'
                        film_cover_vid.style.display = 'block'
                        film_cover.style.transform = 'scale(1.05)'
                        film_details.style.transform = 'translateY(50px)'
                        film_p.style.transform = 'translateY(50px)'
                    }
                    
                    film_trigger.onmouseleave = () => {
                        film_cover_vid.style.opacity = 0
                        film_cover_vid.style.visibility = 'hidden'
                        film_cover_vid.style.display = 'none'
                        film_cover.style.transform = 'scale(1)'
                        film_details.style.transform = 'translateY(0px)'
                        film_p.style.transform = 'translateY(0px)'
                    }
                    
                    card4.onmouseover = () => {
                        illustration_cover.style.transform = 'scale(1.1)'
                        illustration_details.style.transform = 'translateY(max(40px,5vh))'
                    }
                    
                    card4.onmouseleave = () => {
                        illustration_cover.style.transform = 'scale(1)'
                        illustration_details.style.transform = 'translateY(0px)'
                    }
                    
                    
                    
                    //Toggle Sound
                    const banner_mute_img = document.querySelector('.banner_mute img')
                    var sound_bool = true
                    const last_night_trailer = document.querySelector('#last_night_trailer')
                    const reel_video = document.querySelector('#reel_video')
                    
                    sound_bool = !sound_bool
                    
                    banner_mute.onclick = () => {
                        if (sound_bool = !sound_bool) {
                            banner_mute_img.setAttribute('src', sound_on)
                        } else {
                            banner_mute_img.setAttribute('src', sound_off)
                        }
                        
                    
                        last_night_trailer.muted =!last_night_trailer.muted
                    
                    
                    }
                    
                    reel_button.onclick = () => {
                    
                        last_night_trailer.muted=true
                        reel_video.muted = true
                        if(sound_bool==true){
                            banner_mute_img.setAttribute('src', sound_off)
                            sound_bool=false;
                        }
                    
                        if (reel_bool = !reel_bool) {
                            banner_trey.style.left = '-100%'
                            reel_button.innerHTML = '&larr; Last Night'
                            banner_caption.textContent = 'Our Showreel'
                            banner_description.textContent = ' “A project that kept our creative adrenaline juices flowing, it could have been a cocktail recipe."'
                    
                    
                        } else {
                            banner_trey.style.left = '0%'
                            reel_button.innerHTML = 'Showreel &rarr;'
                            banner_caption.textContent = 'Last Night'
                            banner_description.textContent = ' “One. Night. Of. Pleasure? Started out that way The Nightmare was in the Shadows…Waiting”'
                    
                        }
                    
                        if (reel_bool == false) {
                            banner_mute.onclick = () => {
                               
                                if (sound_bool = !sound_bool) {
                                    banner_mute_img.setAttribute('src', sound_on)
                                   
                                } else {
                                    banner_mute_img.setAttribute('src', sound_off)
                                }
                                last_night_trailer.muted =!last_night_trailer.muted
                                
                            }
                        } else {
                            banner_mute.onclick = () => {
                               
                                if (sound_bool = !sound_bool) {
                                    banner_mute_img.setAttribute('src', sound_on)
                                } else {
                                    banner_mute_img.setAttribute('src', sound_off)
                                }
                    
                                reel_video.muted = !reel_video.muted
                            }
                        }
                    
                    }
                    
                    
                    
                    }
                    
                    try {
                        parentFunction()
                        const all_video = document.querySelectorAll('video')

                        all_video.forEach(items=>{
                            items.play()
                        })
                        
                    } catch (error) {
                        
                    }

              
                


            }
        },
        {
            namespace: 'works',
            beforeEnter(){
                
            },
            afterEnter() {

                function MainFunction() {
                    const vizUrl_array = [
                        'd/ozw1YrOj-pY?si=kZonmd3fNp6PvN6q&amp;',
                        'd/m1d2jnzRwgA?si=LLzLwemK7IS0XJ85&amp;',
                        'd/M7eqO0Kb0JY?si=67ZmDVNHxuK_vWQE&amp;',
                        'd/LjWLrtI2ZJ0?si=gUB7SV-oA4ewvLd9&amp;',
                    
                        'd/8zkx8R_lNWU?si=68-rzQ_UcA6xv4pi&amp;',
                        'd/1AHv-xVkF8k?si=zwOIrFMYIppEOYxI&amp;',
                        'd/I6wzhp4g2Cw?si=BU9WV8-6ed_h1kfu&amp;',
                        'd/gqm6vwoHzFI?si=6rBQSxMPfEV5Nihq&amp;',
                        'd/iiooBnAgXOU?si=uPq3wYigHUQSKMhf&amp;',
                        'd/7zq1xQ1vKVw?si=3FDFUigGLp4syVtc&amp;',
                    
                        'd/CDWVeVZa-DA?si=C7Spa6k7F34PQDi9&amp;',
                        'd/CDlowo-jes0?si=yEixi-7nWCZLqO2m&amp;',
                        'd/1j8WzAFcsd0?si=bYxJiTHJSrGI0inb&amp;',
                        'd/jm-7qVrU6Po?si=swM3cbAvUqgI2DSO&amp;',
                    
                    
                    ]
                    
                    const anim_titles = [
                        'Kwesi Arthur - 4lyfe',
                        'Joeboy - Body & Soul',
                        'Joeboy - Loose Ya',
                        'Joeboy - Adenuga ft. Qing Madi',
                        'Joeboy - Concerning',
                        'Yaadman fka Yung L & Wizkid - Eve Bounce (Remix)',
                        'Joeboy - Baby',
                        'Mr Eazi ft Simi - Surrender',
                        'Joeboy - Lonely ',
                        'Reekado Banks - Happy yourself',
                        'Young Jonn, Tiwa Savage - Let Them Know ft. Joeboy',
                        'Akwaboah - One Day',
                        'Joeboy - Focus ',
                        'Joeboy - Call'
                    
                    ]
                    
                    
                    const works = document.querySelector('.works')
                    
                    const viz_array = Object.keys(visualizers)
                    const new_array = []
                
                    const toggleDarkMode = () => {
                        const toggle_mode = document.querySelector('.toggle_mode')
                        var toggle = true
                        var key = 'toggle_key'
                        toggle = !toggle
                        const local_toggle = localStorage.getItem(key)
                
                
                
                        toggle_mode.onclick = () => {
                
                            if (toggle = !toggle) {
                                localStorage.setItem(key, toggle)
                                document.body.classList.add('darkmode')
                
                
                            } else {
                                localStorage.setItem(key, toggle)
                                document.body.classList.remove('darkmode')
                
                            }
                        }
                
                        if (local_toggle === 'true') {
                            document.body.classList.add('darkmode')
                
                        }
                
                        if (local_toggle === 'false') {
                            document.body.classList.remove('darkmode')
                
                        }
                    }
                    toggleDarkMode()
                
                    //Vizualizer animations appending into DOM
                
                    viz_array.sort((a, b) => {
                        const figA = a.replace('viz_', '')
                        const figB = b.replace('viz_', '')
                
                        const numA = Number(figA)
                        const numB = Number(figB)
                
                        return numA - numB
                
                    })
                
                
                    //console.log(viz_array)
                
                    for (let i = 0; i < viz_array.length; i += 2) {
                
                        // console.log(vizUrl_array[i+1])
                        const obj = {
                            key_one: {
                                image_link: viz_array[i],
                                url: vizUrl_array[i],
                                anim_title: anim_titles[i]
                            },
                            key_two: {
                                image_link: viz_array[i + 1],
                                url: vizUrl_array[i + 1],
                                anim_title: anim_titles[i + 1]
                            }
                        }
                
                        new_array.push(obj)
                
                    }
                    // console.log(new_array)
                  
                
                    new_array.forEach((items) => {
                        const vizualizer_container = document.createElement('div')
                        vizualizer_container.classList = 'vizualizer_container'
                
                
                
                        const id_1 = items.key_one.image_link.replace('viz_', '')
                        const id_2 = items.key_two.image_link.replace('viz_', '')
                
                        console.log(items)
                
                        const template = `
                            <div class="vizualizer">
                
                                <div class="viz_img_container" >
                                <img class='viz_image' src="${visualizers[items.key_one.image_link]}" alt="poka studios vizualizers"
                                 data-id=${id_1}  
                                 data-scroll 
                                 data-scroll-speed="0"
                                 data-scroll-position="start,end"
                                 data-scroll-offset="10%,25%"
                                 data-scroll-call="works_trigger"
                                 data-scroll-repeat 
                                 data-url=${items.key_one.url}
                                 data-title="${items.key_one.anim_title}"/>
                                 <div class="glow"  data-scroll data-scroll-speed="0">
                                    <img src="${play_button}" alt="poka studio - play button">
                                 </div>
                                </div>
                
                                 
                
                             </div>
                
                             <div class="vizualizer">
                
                                <div class="viz_img_container"
                                 data-scroll 
                                 data-scroll-speed="0.15">
                
                                <img class='viz_image' src="${visualizers[items.key_two.image_link]}" alt="poka studios vizualizers"
                                 data-id=${id_2}  
                                 data-scroll 
                                 data-scroll-position="start,end"
                                 data-scroll-offset="10%,50%"
                                 data-scroll-call="works_trigger"
                                 data-scroll-repeat  
                                 data-url=${items.key_two.url}
                                 data-title="${items.key_two.anim_title}"/>
                
                                 <div class="glow"
                                 data-scroll  
                                 data-scroll-speed="0"
                                 >
                                 <img src="${play_button}" alt="poka studio - play button">
                                 </div>
                                </div>
                
                                 
                             </div>
                        `
                       
                        vizualizer_container.innerHTML = template
                        works.appendChild(vizualizer_container)
                        
                    })
                
                    //Click to play video function begins here...
                
                    const iframe_container = document.querySelector('.iframe_container')
                    const iframe = document.querySelector('iframe')
                
                    document.addEventListener('click', (e) => {
                        const target = e.target.closest('.glow')
                
                
                
                        if (target) {
                
                            const viz = target.previousElementSibling
                            const data_url = viz.getAttribute('data-url')
                            const iframe_url = "https://www.youtube.com/embed/a&amp;controls=1"
                
                            iframe_container.style.cssText = `
                                display:block;
                            `
                
                            var new_url = iframe_url.replace(/d(.*?)&/, `${data_url}`)
                            iframe.src = new_url
                            console.log(new_url)
                
                
                
                        }
                    })
                
                    const close = document.querySelector('.close')
                
                    close.onclick = () => {
                        iframe_container.style.display = 'none'
                        iframe.src = 'about:blank'
                        iframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
                    }
                
                 
                
                   setTimeout(()=>{
                
                    const glow = document.querySelectorAll('.glow');
                    const viz_image = document.querySelector('.viz_image')
                    const imageHeight = viz_image.getBoundingClientRect()
                    //console.log(viz_image.getBoundingClientRect())
                
                    glow.forEach((items, index) => {
                        items.style.height = imageHeight.height + 'px'
                
                    })
                
                
                    //Card 3d hover effect
                    const allVizImages = document.querySelectorAll('.viz_image')
                
                    let cardSize;
                    glow.forEach(items => {
                        const rotateToMouse = (e) => {
                            const mouseX = e.clientX
                            const mouseY = e.clientY
                            const leftX = mouseX - cardSize.x
                            const topY = mouseY - cardSize.y
                
                            const center = {
                                x: leftX - cardSize.width / 2,
                                y: topY - cardSize.height / 2
                            }
                
                            const distance = Math.sqrt(center.x ** .5 + center.y ** 2)
                
                
                            // allVizImages.forEach(cards=>{
                
                            //     cards.style.transform = `
                            //     rotate3d(
                            //     ${center.y / 100},
                            //     ${center.x / 100},
                            //     0,
                            //     ${Math.log(distance) * 2}deg
                            //     )
                            //      `
                
                            // })
                
                
                
                
                            items.style.backgroundImage = `
                                radial-gradient(
                                  circle at
                                  ${center.x * 2 + cardSize.width / 2}px
                                  ${center.y * 2 + cardSize.height / 2}px,
                                  #ffffff55,
                                  #0000000f
                                )
                              `
                
                            items.style.cursor = 'pointer'
                
                        }
                
                        items.addEventListener('mouseenter', () => {
                            cardSize = items.getBoundingClientRect()
                            document.addEventListener('mousemove', rotateToMouse)
                
                        })
                
                        items.addEventListener('mouseleave', () => {
                            document.removeEventListener('mousemove', rotateToMouse)
                            items.style.transform = ''
                            items.style.backgroundImage = ''
                        })
                    })
                
                    const tl = gsap.timeline()
                
                    //Scroll trigger functions begin here...
                    window.addEventListener('works_trigger', (e) => {
                        const anim_title = document.querySelector('.anim_title')
                        const anim_title_h3 = document.querySelector('.anim_title h3')
                        const current_img = document.querySelector('.current_img')
                        const target = e.detail
                        const way = e.detail.way
                        const in_view = target.target
                        const target_title = in_view.getAttribute('data-title')
                        const target_img = in_view.getAttribute('src')
                        const card_parent = in_view.parentElement.parentElement
                
                        anim_title_h3.textContent = target_title
                        current_img.setAttribute('src', target_img)
                        anim_title.style.display = 'block'
                
                        tl.to(card_parent, {
                            opacity: 1,
                            scale: 1,
                            y: 0,
                            ease: 'power4.out'
                        })
                
                
                    })
                
                    //Set intitial animation values with gsap.set
                    allVizImages.forEach(items => {
                        const viz_id = items.getAttribute('data-id')
                
                
                        if (viz_id > 1) {
                            const card_parent = items.parentElement.parentElement
                            console.log(card_parent)
                            tl.set(card_parent, {
                                //test value
                                opacity: 0,
                                scale: .95,
                                y: 100
                            })
                        }
                    })
                
                    //Dom load setTimout Function ends here...
                    },500)
                
                    
                
                    //Gradient backgroudn height adaptation functions begins here...
                    const gradient_background = document.querySelector('.gradient_background')
                    const works_container = document.querySelector('.works_container')
                
                    const works_height = works_container.getBoundingClientRect().height
                    gradient_background.style.height = works_height + 'px'
                
                
                
                    //Responsive Navbar
                    const nav = document.querySelector('nav')
                    var lastScrollPosition = 0
                
                    window.addEventListener('scroll', () => {
                        // Get the current scroll position
                        var currentScrollPosition = window.scrollY;
                        //console.log(currentScrollPosition)
                
                        if (currentScrollPosition - lastScrollPosition > 0) {
                            nav.classList.add('add_nav')
                        } else {
                            nav.classList.remove('add_nav')
                            nav.style.height = 'auto'
                        }
                
                        lastScrollPosition = currentScrollPosition
                    })
                
                    window.addEventListener('contactTrigger', () => {
                
                        const challenge_img = document.querySelector('.challenge_img')
                        const challenge_content = document.querySelector('#challenge_content')
                        const contact_img = document.querySelector('.contact_img')
                        const contact_content = document.querySelector('#contact_content')
                        const footer_arr = [challenge_img, challenge_content, contact_img, contact_content]
                
                        gsap.to(footer_arr, {
                            alpha: 1,
                            stagger: .2,
                            duration: 1.5,
                            ease: 'power4.out'
                        })
                    })
                
                    // const iframe = document.querySelector('iframe')
                    var string_one = iframe.getAttribute('src')
                    const text = 'd/bobby&amp;'
                    var new_url = string_one.replace(/d(.*?)&/, `${text}`)
                    //console.log(new_url)
                
                    locomotiveScroll.start()
                
                    //End of MainFunction//
                }
                
                try {
                    MainFunction()
                    
                } catch (error) {

                }
            }
        }
    ]
})






